.label-input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    margin-left: 10px;
    margin-bottom: 5px;
    display: block;
}

.input-select {
    display: block;
    width: 410px;
    position: relative;
}

.options-div {
    width: 434px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    position: absolute;
    background-color: white;
}

.option {
    display: flex;
    align-items: center;
    cursor: pointer;
}

