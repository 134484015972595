.filterDiv{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.addButtons{
    margin-left: 20px;
    display: flex;
    padding-top: 26px;
}

@media only screen and (max-width: 1170px) {
    .filterDiv {
        display: block;
    }
    .addButtons{
        padding-top: 0;
    }
}