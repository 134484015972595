.input {
    border: 1px solid rgba(0, 0, 0, 0.19);
    width: 100%;
    height: 50px;
    border-radius: 5px;
    font-size: 18px;
    color: #545150;
    padding-left: 20px;
    display: block;
}

.label {
    display: block;
    font-size: 20px;
    margin: 10px 0 10px 10px;
}







