.label-input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    margin-left: 10px;
    margin-bottom: 5px;
    display: block;
}

.input-default {
    display: block;
    width: 410px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s;
    outline: none;
}


.div-input {
    margin-top: 10px;
}

a {
    font-size: 14px;
    color: blue;
    /* text-decoration: underline; */
    cursor: pointer;
}


.submit-button {
    margin-top: 20px;
    cursor: pointer;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    font-weight: 400;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}