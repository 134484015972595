.button-div {
    margin: 20px 27px;
    float: right;
}


.button-submit {
    height: 30px;
    width: 100px;
    background: white;
    font-size: 16px;
    color: #515D9C;
    font-weight: 500;
    border: 1px solid;
}

