.apps-content {
    padding: 50px;
}


.filter-input {
    height: auto !important;
    width: 30% !important;
}

