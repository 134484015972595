.table {
  width: 100%;
  border-width: 1px;
  border-collapse: collapse;
  border-radius: 14px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px rgba(84, 51, 80, 0.26); /* this draws the table border  */
}

.table-head {
  color: #a32526;
}

.table-data {
  text-align: center;
}

.table-row {
  border-top: 1px solid rgba(84, 81, 80, 0.26);
}

/* 
.table-row:hover {
    cursor: pointer;
    background-color: rgba(81, 93, 156, 0.1)
} */

.table tbody:last-child {
  border-bottom-left-radius: 14px;
}

.table tbody:last-child > tr:last-child {
  border-bottom-right-radius: 14px;
}

td {
  text-align: center;
}
