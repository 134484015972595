.popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.7);
}


.content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    z-index: 2;
    background-color: #fff;
    width: 600px;
    /* height: 70%; */
    padding-bottom: 50px;
    transform: translate(-50%, -50%);
    /* text-align: center; */
    padding:20px;
    box-sizing: border-box;
}

.content .close {
    position: absolute;
    right: 15px;
    top: 0px;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    font-size: 25px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}